<template>
  <div id="Partners">
    <div class="info">
      <img src="@/assets/images/partners/banner_p_c.png" alt="" />
      <div>
        <h2>伙伴与客户</h2>
        <!-- <p>Partners And Customers</p> -->
      </div>
    </div>
    <div class="partners-box">
      <ul>
        <li v-for="(item, index) in PartnersList" :key="index">
          <!-- :style="{
            backgroundImage: 'url(' + item.img + ')',
          }" -->
          <img :src="item.img" />
          <div>
            <div>
              <span>{{ item.name }}</span>
              <!-- <span>{{ item.english_name }}</span> -->
            </div>
            <el-button type="primary" @click.stop="$router.push(`${item.url}`)">
              了解更多
            </el-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import bgPcItem01 from './../../assets/images/partners/bg_p_c_item_01.png'
import bgPcItem02 from './../../assets/images/partners/bg_p_c_item_02.png'
import bgPcItem03 from './../../assets/images/partners/bg_p_c_item_03.png'

export default {
  name: 'Partners',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {
      PartnersList: [
        {
          name: '合作企业',
          english_name: 'Joint Venture',
          img: bgPcItem01,
          url: '/partners-customers/business',
        },
        {
          name: '合作学校',
          english_name: 'Joint School',
          img: bgPcItem02,
          url: '/partners-customers/school',
        },
        {
          name: '经典案例',
          english_name: 'Classic Case',
          img: bgPcItem03,
          url: '/partners-customers/case',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)

    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Partners {
  width: 100%;
  position: relative;
  background: #f6f5f5;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('height', 1255);
  .info {
    position: relative;
    img {
      width: 100%;
    }
    div {
      text-align: center;
      @include px2vw('width', 382);
      @include px2vw('padding', 70);
      @include px2vw('padding-bottom', 31);
      @include px2vw('padding-top', 31);
      background: rgba(0, 0, 0, 0.3);
      color: $--color-white;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 25%;
      left: 37%;
      h2 {
        @include px2vw('font-size', 56);
        font-weight: normal;
        margin: 0;
        margin-top: 10px;
        letter-spacing: 4px;
      }
      p {
        margin: 0;
        @include px2vw('font-size', 33);
        @include px2vw('margin-top', 32);
        letter-spacing: 1px;
      }
    }
  }
  .partners-box {
    position: relative;
    width: 90%;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -12%;
    ul {
      // width: 67%;
      @include px2vw('height', 382);
      margin: 0 auto;
      background: $--color-white;
      display: flex;
      padding: 90px 79px;
      justify-content: space-between;
      li {
        height: 100%;
        width: 30%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.6s;
        }
        &:hover {
          > img {
            transform: scale(1.1);
          }
        }
        > div {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: unset;
          align-items: flex-end;
          justify-content: space-between;
          padding: 40px 34px;
          box-sizing: border-box;
          z-index: 2;
          > div {
            display: flex;
            flex-direction: column;
            span {
              margin-top: 15px;
              color: #222;
              &:nth-of-type(1) {
                font-size: 29px;
              }
              &:nth-of-type(2) {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
}

.view-pc > #Partners {
  @extend %Partners;
  @media screen and (max-width: 1200px) {
    .partners-box {
      ul {
        li {
          > div {
            span {
              font-size: 22px !important;
              margin-top: 0 !important;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1480px) {
    // @include px2vw('height', 1450);
    .partners-box {
      width: 90%;
      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
      ul {
        @include px2vw('height', 450);
        padding: 70px 45px;
        li {
          > div {
            // flex-direction: column;
            align-items: flex-end;
            padding: 14px 18px;
            justify-content: center;
            padding-bottom: 30px;
            > div {
              margin-right: 20px;
            }
            span {
              margin-top: 10px;
              color: #222;
              &:nth-of-type(1) {
                font-size: 26px;
              }
              &:nth-of-type(2) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.view-mobile > #Partners {
  @extend %Partners;
  // @include px2vw('height', 3000);
  .info {
    margin-top: 44px;
    div {
      width: 100vw;
      height: 75%;
      padding: 0;
      padding-top: 10%;
      top: 0;
      left: 0;
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  .partners-box {
    top: 9%;
    ul {
      padding: 25px;
      flex-direction: column;
      @include px2vw('height', 1746);
      li {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: 1% 47%;
        align-items: center;
        > div {
          padding: 25px 22px;
          align-items: center;

          > div {
            span {
              &:nth-of-type(1) {
                font-size: 16px;
                margin-top: 0;
              }
              &:nth-of-type(2) {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
